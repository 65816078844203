import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone';
import { Form, Image } from 'react-bootstrap';

import './Components.scss'

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

const MetaBlock = (props) => {
    const [isMetaOpen, setIsMetaOpen] = useState(false);

    const toggleMetaInformation = () => {
        setIsMetaOpen(!isMetaOpen);
    };

    const onDrop = React.useCallback(acceptedFiles => {
        props.setAvatar(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    return (
        <div className="meta_block">
            <div className="meta_header_block">
                <p>Meta Data:</p>
                <FontAwesomeIcon
                    className="toggle_meta"
                    onClick={toggleMetaInformation}
                    icon={isMetaOpen ? faAngleUp : faAngleDown}
                />
            </div>
            {isMetaOpen && (
                <div className="meta_information_block">
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Название проекта:</Form.Label>
                        <Form.Control className="form_input"
                                      type="text"
                                      value={props.projectTitle}
                                      onChange={e => props.setProjectTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Meta Title:</Form.Label>
                        <Form.Control className="form_input"
                                      type="text"
                                      value={props.metaTitle}
                                      onChange={e => props.setMetaTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Meta Description:</Form.Label>
                        <Form.Control className="form_input"
                                      type="text"
                                      value={props.metaDescription}
                                      onChange={e => props.setMetaDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Ключевые слова:</Form.Label>
                        <Form.Control className="form_textarea"
                                      as="textarea"
                                      value={props.keywords}
                                      onChange={e => props.setKeywords(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Аватар проекта:</Form.Label>
                        <div {...getRootProps({ className: 'dropzone' })} className="form_image_input">
                            <input {...getInputProps()} />
                            {
                                props.avatar ?
                                <div className="image_selection">
                                    <p>Перетащите сюда файл или нажмите для выбора файла</p>
                                    <p>(текущий файл: {props.avatar.name})</p>
                                </div> :
                                <p>Перетащите сюда файл или нажмите для выбора файла</p>
                            }
                        </div>
                        {props.avatar && (
                            <div className="image_preview">
                                <Image src={URL.createObjectURL(props.avatar)} alt="Аватар проекта" fluid />
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className="form_group">
                        <Form.Label className="form_label">Ссылка:</Form.Label>
                        <Form.Control className="form_input"
                                      type="text"
                                      value={props.link}
                                      onChange={e => props.setLink(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="form_group_checkbox">
                        <Form.Label className="form_label_checkbox">Я все проверил и актуальной ссылки точно нет:</Form.Label>
                        <Form.Check className="form_input_checkbox"
                                    type="checkbox"
                                    checked={props.check}
                                    onChange={e => props.setCheck(e.target.checked)}
                        />
                    </Form.Group>
                </div>
            )}
        </div>
    );
};

export default MetaBlock;
