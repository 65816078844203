import React, {useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";

import MainPage from "./MainPage";
import TextEditor from "./components/TextEditor/TextEditor";

import axios from "axios";


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "https://195.2.71.88"
});

function App() {

  const isCurrentUser = localStorage.getItem("CurrentUser") === 'true';
  const userData = localStorage.getItem("currentUserData");

  const [currentUser, setCurrentUser] = useState(isCurrentUser);
  const [currentUserData, setCurrentUserData] = useState(JSON.parse(userData) || '');

  useEffect(() => {
    client.get("/api/user")
        .then(function(res) {
          setCurrentUserData(res.data.user)
          setCurrentUser(true);

          window.localStorage.setItem('currentUserData', JSON.stringify(res.data.user));
          localStorage.setItem('CurrentUser', true);

        })
        .catch(function(error) {
            setCurrentUserData('')
            setCurrentUser(false);
        });
    }, []);

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie('csrftoken');

  return (
    <div>
      <Routes>
          <Route path='/'
                 element={<MainPage
                     client={client} currentUser={currentUser} setCurrentUser={setCurrentUser}
                     currentUserData={currentUserData} setCurrentUserData={setCurrentUserData}
                     csrftoken={csrftoken}
                 />}
          />
          <Route path='/text_editor'
                 element={currentUserData ? (
                     <TextEditor
                         client={client} currentUser={currentUser} setCurrentUser={setCurrentUser}
                         currentUserData={currentUserData} setCurrentUserData={setCurrentUserData}
                         csrftoken={csrftoken}
                     />
                 ) : (
                     <Navigate to='/' />
                 )}
          />
      </Routes>
    </div>
  );
}

export default App;
