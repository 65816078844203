import React, { useState, useEffect, useRef } from "react";
import { convertToRaw, EditorState, RichUtils, Modifier, SelectionState, AtomicBlockUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import "draft-js/dist/Draft.css";

import draftToHtml from "draftjs-to-html";
import MenuToolBox from "./MenuToolBox";
import MetaBlock from "./MetaBlock";
import './Components.scss'


const customLabels = {
  // Generic
  'generic.add': 'Добавить',
  'generic.cancel': 'Отменить',

  // BlockType
  'components.controls.blocktype.h1': 'H1',
  'components.controls.blocktype.h2': 'H2',
  'components.controls.blocktype.h3': 'H3',
  'components.controls.blocktype.h4': 'H4',
  'components.controls.blocktype.h5': 'H5',
  'components.controls.blocktype.h6': 'H6',
  'components.controls.blocktype.blockquote': 'Цитата',
  'components.controls.blocktype.code': 'Code',
  'components.controls.blocktype.blocktype': 'Block Type',
  'components.controls.blocktype.normal': 'Абзац',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Color Picker',
  'components.controls.colorpicker.text': 'Text',
  'components.controls.colorpicker.background': 'Highlight',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Embedded Link',
  'components.controls.embedded.enterlink': 'Enter link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Font',

  // FontSize
  'components.controls.fontsize.fontsize': 'Font Size',

  // History
  'components.controls.history.history': 'History',
  'components.controls.history.undo': 'Undo',
  'components.controls.history.redo': 'Redo',

  // Image
  'components.controls.image.image': 'Вставить изображение',
  'components.controls.image.fileUpload': 'Файл',
  'components.controls.image.byURL': 'Ccылка',
  'components.controls.image.dropFileText': 'Drop the file or click to upload',

  // Inline
  'components.controls.inline.bold': 'Bold',
  'components.controls.inline.italic': 'Italic',
  'components.controls.inline.underline': 'Underline',
  'components.controls.inline.strikethrough': 'Strikethrough',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Superscript',
  'components.controls.inline.subscript': 'Subscript',

  // Link
  'components.controls.link.linkTitle': 'Link Title',
  'components.controls.link.linkTarget': 'Link Target',
  'components.controls.link.linkTargetOption': 'Open link in new window',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Unlink',

  // List
  'components.controls.list.list': 'List',
  'components.controls.list.unordered': 'Неупорядоченный список',
  'components.controls.list.ordered': 'Пронумерованный список',
  'components.controls.list.indent': 'Indent',
  'components.controls.list.outdent': 'Outdent',

  // Remove
  'components.controls.remove.remove': 'Remove',

  // TextAlign
  'components.controls.textalign.textalign': 'Text Align',
  'components.controls.textalign.left': 'Left',
  'components.controls.textalign.center': 'Center',
  'components.controls.textalign.right': 'Right',
  'components.controls.textalign.justify': 'Justify',
};

const styleMap = {
    RED: {
        color: 'red',
    }
};

const labels = [
    'Заголовок H1', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
    'Заголовок H2', 'Контент',
];


const DraftEditor = (props) => {
    const [projectId, setProjectId] = useState(null);

    const [editorStates, setEditorStates] = useState(
        labels.map(() => EditorState.createEmpty())
    );

    const [activeEditor, setActiveEditor] = useState(0);
    const editorRefs = useRef([]);

    const [uploadedImages, setUploadedImages] = useState([]);

    const [projectTitle, setProjectTitle] = useState("Новый проект");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    const [avatar, setAvatar] = React.useState(null);
    const [link, setLink] = React.useState("");
    const [check, setCheck] = useState(false);

    const [fakeText, setFakeText] = useState([]);

    const [publicationLink, setPublicationLink] = useState("");


    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 35) {
            setSticky(true);
          } else {
            setSticky(false);
          }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const copyToClipboard = () => {
        if (publicationLink) {
            navigator.clipboard.writeText(publicationLink).then(() => {
                alert('Ссылка скопирована');
            }).catch(err => {
                console.error('Ошибка при копировании: ', err);
            });
        }
    };

    const applyColorToText = (searchTexts) => {
        editorStates.forEach((editorState, index) => {
            let contentState = editorState.getCurrentContent();
            const blockMap = contentState.getBlockMap();

            let newContentState = contentState;

            blockMap.forEach(block => {
                const blockKey = block.getKey();
                const blockText = block.getText();

                searchTexts.forEach(searchText => {
                    let start = 0;
                    let end = blockText.indexOf(searchText, start);

                    while (end !== -1) {
                        const selection = SelectionState.createEmpty(blockKey).merge({
                            anchorOffset: end,
                            focusOffset: end + searchText.length,
                        });

                        newContentState = Modifier.applyInlineStyle(
                            newContentState,
                            selection,
                            'RED'
                        );

                        start = end + searchText.length;
                        end = blockText.indexOf(searchText, start);
                    }
                });
            });

            const newEditorState = EditorState.push(editorState, newContentState, 'change-inline-style');
            setEditorStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = newEditorState;
                return newStates;
            });
        });
    };

    useEffect(() => {
        if (fakeText.length > 0) {
            applyColorToText(fakeText);
        }
    }, [fakeText]);

    const handleEditorClick = (index) => {
        setActiveEditor(index);
    };

    const handleEditorStateChange = (state, index) => {
        let newState = state;
        if (index % 2 === 0) {
            const currentContent = newState.getCurrentContent();
            const selection = newState.getSelection();
            const blockKey = selection.getStartKey();
            const blockType = currentContent.getBlockForKey(blockKey).getType();

            if (index === 0 && blockType !== 'header-one') {
                newState = RichUtils.toggleBlockType(newState, 'header-one');
            }
            if (index !== 0 && blockType !== 'header-two') {
                newState = RichUtils.toggleBlockType(newState, 'header-two');
            }
        }

        const newEditorStates = [...editorStates];
        newEditorStates[index] = newState;
        setEditorStates(newEditorStates);
    };

    const handleBeforeInput = (chars, editorState, index) => {
        if (index % 2 !== 0) {
            if (chars === ' ' || chars === '- ') {
                const contentState = editorState.getCurrentContent();
                const selection = editorState.getSelection();
                const blockKey = selection.getStartKey();
                const block = contentState.getBlockForKey(blockKey);
                const blockText = block.getText();

                if (blockText === '-') {
                    const newContentState = Modifier.setBlockType(
                        contentState,
                        selection,
                        'ordered-list-item'
                    );

                    const newContentStateWithTextRemoved = Modifier.replaceText(
                        newContentState,
                        selection.merge({
                            anchorOffset: selection.getAnchorOffset() - 2,
                            focusOffset: selection.getFocusOffset(),
                        }),
                        ' '
                    );

                    const newEditorState = EditorState.push(
                        editorState,
                        newContentStateWithTextRemoved,
                        'change-block-type'
                    );

                    setEditorStates(prevStates => {
                        const newStates = [...prevStates];
                        newStates[index] = newEditorState;
                        return newStates;
                    });
                    return 'handled';
                }
            }
        }
        return 'not-handled';
    };

    const uploadCallback = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                setUploadedImages(prevImages => [
                    ...prevImages,
                    { file, base64data }
                ]);
                resolve({ data: { link: base64data } });
            };
            reader.readAsDataURL(file);
        });
    };

    const findFileNameByBase64 = (base64data, uploadedImages) => {
        const foundImage = uploadedImages.find(image => image.base64data === base64data);

        return foundImage ? foundImage.file.name : null;
    };

    const insertImage = (editorState, base64data) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: base64data }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );
        return EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter());
    };

    const handleDrop = (e, index) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64data = reader.result;
            setUploadedImages(prevImages => [
                ...prevImages,
                { file, base64data }
            ]);

            const newEditorState = insertImage(editorStates[index], base64data);
            handleEditorStateChange(newEditorState, index);
        };

        reader.readAsDataURL(file);
    };

    const handlePastedText = (text, html, editorState, index) => {
        if (!props.currentUserData['text_insert_permission']) {
            return 'handled';
        }
        return 'not-handled';
    };

    const filterEmptyBlocks = (rawContent) => {
        const { blocks, entityMap } = rawContent;
        const updatedEntityMap = {};

        if (!Array.isArray(blocks) || typeof entityMap !== 'object') {
            return rawContent;
        }

        const filteredBlocks = blocks.filter(block => {
            const text = block.text.trim();
            const entityKey = block.entityRanges.length > 0 ? block.entityRanges[0].key : null;

            if (text !== "") {
                return true;
            }

            if (entityKey !== null) {
                const entity = entityMap[entityKey];
                if (entity && entity.type === 'IMAGE') {
                    const data = entity.data;
                    if (data && data.src) {
                        const fileName = findFileNameByBase64(data.src, uploadedImages);
                        if (fileName) {
                            updatedEntityMap[entityKey] = {
                                ...entity,
                                data: {
                                    ...data,
                                    src: fileName
                                }
                            };
                            return true;
                        }
                    }
                }
            }

            return false;
        });

        return {
            ...rawContent,
            blocks: filteredBlocks,
            entityMap: {
                ...entityMap,
                ...updatedEntityMap
            }
        };
    };

    const collectAllHtml = () => {
        return editorStates
            .map(state => draftToHtml(filterEmptyBlocks(convertToRaw(state.getCurrentContent())))).join('');
    };

    let htmlText = collectAllHtml();

    return (
        <div className="text_editor">
            <MenuToolBox
                client={props.client}
                csrftoken={props.csrftoken}
                htmlText={htmlText}
                projectTitle={projectTitle}
                setProjectTitle={setProjectTitle}
                metaTitle={metaTitle}
                setMetaTitle={setMetaTitle}
                metaDescription={metaDescription}
                setMetaDescription={setMetaDescription}
                keywords={keywords}
                setKeywords={setKeywords}
                avatar={avatar}
                setAvatar={setAvatar}
                link={link}
                setLink={setLink}
                check={check}
                setCheck={setCheck}
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                setFakeText={setFakeText}
                setPublicationLink={setPublicationLink}
                projectId={projectId}
                setProjectId={setProjectId}
                currentUserData={props.currentUserData}
                editorStates={editorStates}
                setEditorStates={setEditorStates}
                sticky={sticky}
            />
            <div className="editor_block">
                {publicationLink && (
                    <div className={`${sticky ? "publication_block sticky_publ_class" : "publication_block no_sticky_publ_class"}`}>
                        <button onClick={copyToClipboard}>Скопировать ссылку на документ</button>
                    </div>
                )}
                <MetaBlock
                    projectTitle={projectTitle}
                    setProjectTitle={setProjectTitle}
                    metaTitle={metaTitle}
                    setMetaTitle={setMetaTitle}
                    metaDescription={metaDescription}
                    setMetaDescription={setMetaDescription}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    avatar={avatar}
                    setAvatar={setAvatar}
                    link={link}
                    setLink={setLink}
                    check={check}
                    setCheck={setCheck}
                />
                {labels.map((label, index) => (
                    <div
                        key={index}
                        data-label={index}
                        onClick={() => handleEditorClick(index)}
                        className="semantic_block"
                        onDrop={(e) => handleDrop(e, index)}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <div className="header_wrapper">
                            <div className="header_block">
                                <p>{label}:</p>
                            </div>
                        </div>
                        <Editor
                            ref={el => editorRefs.current[index] = el}
                            editorState={editorStates[index]}
                            onEditorStateChange={(state) => {
                                handleEditorStateChange(state, index);
                            }}
                            toolbarClassName={`${activeEditor === index ? "toolbar_class toolbar_active" : "toolbar_class"} ${sticky ? "sticky_class" : "no_sticky_class"}`}
                            wrapperClassName="wrapper_class"
                            editorClassName={index % 2 === 0 ? "header_editor_class" : "editor_class"}
                            handleBeforeInput={(chars, editorState) => handleBeforeInput(chars, editorState, index)}
                            handlePastedText={(text, html, editorState) => handlePastedText(text, html, editorState, index)}
                            localization={{ locale: 'ru', translations: customLabels }}
                            customStyleMap={styleMap}
                            toolbar={{
                                options: ['blockType', 'list', 'image'],
                                blockType: {
                                    inDropdown: false,
                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ['unordered', 'ordered'],
                                },
                                image: {
                                    uploadCallback: uploadCallback,
                                    previewImage: true,
                                    urlEnabled: false
                                }
                            }}
                            spellCheck
                            handlePastedFiles={(files) => {
                                const file = files[0];
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const contentState = editorStates.getCurrentContent();
                                    const contentStateWithEntity = contentState.createEntity(
                                        'IMAGE',
                                        'IMMUTABLE',
                                        { src: reader.result }
                                    );
                                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                                    const newContentState = Modifier.insertText(
                                        contentState,
                                        editorStates.getSelection(),
                                        ' ',
                                        null,
                                        entityKey
                                    );
                                    const newEditorState = EditorState.push(
                                        editorStates,
                                        newContentState,
                                        'insert-characters'
                                    );
                                    handleEditorStateChange(newEditorState, index);
                                    setUploadedImages(prevImages => [...prevImages, file]);
                                };
                                reader.readAsDataURL(file);
                                return 'handled';
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DraftEditor;
