import React from "react";

import Header from "./components/Header/Header";
import LogIn from "./components/LogIn/LogIn";


const MainPage = (props) => {

    return (
        <div>
            <Header client={props.client} currentUser={props.currentUser} setCurrentUser={props.setCurrentUser}
                    setCurrentUserData={props.setCurrentUserData}>
            </Header>
            <LogIn client={props.client} currentUser={props.currentUser} setCurrentUser={props.setCurrentUser}
                   setCurrentUserData={props.setCurrentUserData} csrftoken={props.csrftoken}>
            </LogIn>
        </div>
    );
}

export default MainPage;