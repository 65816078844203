import React, { useState, useEffect } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Components.scss'

import { faXmark } from '@fortawesome/free-solid-svg-icons'

const ProjectsView = (props) => {

    const [userProjects, setUserProjects] = useState([]);

    useEffect(() => {
        props.client.get(`/api/get_user_projects/${props.currentUserData['user_id']}`,
            {headers: {'X-CSRFToken': props.csrftoken}})
        .then(function(res) {
            if (res.data['data']) {
                 setUserProjects(res.data['data'])
            } else {
                setUserProjects([])
            }
        })
        .catch(function(res) {
            console.log("Error with user projects: ", res)
        });
    }, []);

    function submitProjectDelete(e, project_id) {
        e.preventDefault();

        props.client.delete(
            `/api/delete_user_project/${project_id}`,
            {headers: {
                'X-CSRFToken': props.csrftoken,
            }}
        ).then(function(res) {
            props.client.get(`/api/get_user_projects/${props.currentUserData['user_id']}`,
                {headers: {'X-CSRFToken': props.csrftoken}})
            .then(function(res) {
                if (res.data['data']) {
                     setUserProjects(res.data['data'])
                } else {
                    setUserProjects([])
                }
            })
            .catch(function(res) {
                console.log("Error with user projects: ", res)
            });
        }).catch(function(res) {
            console.log("Project Delete Error: ", res)
        });
    }

    function submitProjectOpen(e, project_id) {
        e.preventDefault();

        const getMimeTypeFromBase64 = (base64) => {
            const match = base64.match(/^data:(image\/[a-zA-Z]*);base64,/);
            return match ? match[1] : 'image/jpeg';
        };

        const base64ToFile = (base64, fileName) => {
            const mimeType = getMimeTypeFromBase64(base64);
            const base64String = base64.split(',')[1];

            try {
                const byteCharacters = atob(base64String);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);

                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: mimeType });
                return new File([blob], fileName, { type: mimeType });
            } catch (e) {
                console.error('Error converting base64 to File:', e);
                return null;
            }
        };

        const base64ToFiles = (base64, fileName) => {
            const mimeType = getMimeTypeFromBase64(base64);
            const base64String = base64.split(',')[1];

            try {
                const byteCharacters = atob(base64String);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);

                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: mimeType });
                const file = new File([blob], fileName, { type: mimeType });

                return { file, base64data: base64 };
            } catch (e) {
                console.error('Error converting base64 to File:', e);
                return null;
            }
        };

        function setMetaData(jsonData) {

            props.setMetaTitle(jsonData['meta_title'])
            props.setMetaDescription(jsonData['meta_description'])
            props.setKeywords(jsonData['keywords'])
            props.setLink(jsonData['link'])
            props.setCheck(jsonData['check'])

            if (jsonData['avatar']) {
                const fileName = jsonData['avatar_name'] || 'default_image_name.jpg';
                props.setAvatar(base64ToFile(jsonData['avatar'], fileName))
            }

            if (jsonData['files']) {
                const filesList = Object.entries(jsonData['files']).map(([fileName, base64Data]) => {
                    const fileObject = base64ToFiles(base64Data, fileName);
                    return fileObject ? { file: fileObject.file, base64data: fileObject.base64data } : null;
                }).filter(item => item !== null);

                props.setUploadedImages(filesList);
            }
        }

        function setMainData(data) {
            const deserializedStates = data.states.map(rawState =>
                    EditorState.createWithContent(convertFromRaw(rawState))
                );
            props.setEditorStates(deserializedStates);
        }

        props.client.get(
            `/api/open_user_project/${project_id}`,
            {headers: {
                'X-CSRFToken': props.csrftoken,
            }}
        ).then(function(res) {
            if (res.data['data']) {
                props.setProjectsViewToggle(false)
                props.setProjectId(res.data['data']['worksheet_project_id'])
                props.setProjectTitle(res.data['data']['project_title'])
                setMetaData(res.data['data']['meta_data_json'])
                setMainData(res.data['data']['main_data_json'])
            }
        }).catch(function(res) {
            console.log("Project Open Error: ", res)
        });
    }

    return (
        <div className="project_view_block">
            <div className="project_view_menu">
                <div className="project_view_header">
                    <p>Мои незавершенные проекты</p>
                    <FontAwesomeIcon className="project_view_close" icon={faXmark}
                                     onClick={() => props.setProjectsViewToggle(false)}/>
                </div>
                <div className="projects_item_block">
                    {userProjects.length === 0 ? (
                        <div className="no_project_item_block">
                            <p>Проекты отсутствуют</p>
                        </div>
                    ) : (
                        <>
                            {Array.isArray(userProjects) && userProjects.map((item, index) => (
                                item.worksheet_project_id === props.projectId ? (
                                    <div
                                        key={index}
                                        className="project_item_block"
                                        data-index={index}
                                    >
                                        <p>№{index + 1} {item.project_title} (текущий проект)</p>
                                        <span>Дата посл. обновления: {item.last_updated}</span>
                                        <div className="hidden">
                                            <button>Открыть</button>
                                            <button>Удалить</button>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={index}
                                        className="project_item_block"
                                        data-index={index}
                                    >
                                        <p>№{index + 1} {item.project_title}</p>
                                        <span>Дата посл. обновления: {item.last_updated}</span>
                                        <div className="item_action_menu">
                                            <button
                                                onClick={e => submitProjectOpen(
                                                    e, item.worksheet_project_id)}
                                            >
                                                Открыть
                                            </button>
                                            <button
                                                onClick={e => submitProjectDelete(
                                                    e, item.worksheet_project_id)}
                                            >
                                                Удалить
                                            </button>
                                        </div>
                                    </div>
                                )
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectsView;
