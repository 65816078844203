import React from 'react';

import './Header.scss'


const Header = (props) => {

    function submitLogout(e) {
        e.preventDefault();
        props.client.post(
          "/api/logout",
          {withCredentials: true}
        ).then(function(res) {
          props.setCurrentUser(false);
          props.setCurrentUserData('')
        });
    }

    return (
        <div>
            <header>
                <div className="header_block">
                    <div></div>
                    <div className="main_link">
                        <p className="header_link">Text Editor</p>
                    </div>
                    {props.currentUser ? (
                        <div className="right_block">
                            <form onSubmit={e => submitLogout(e)}>
                              <button type="submit">Выход</button>
                            </form>
                        </div>
                    ) : (
                        <>
                            <div></div>
                        </>
                    )}
                </div>
            </header>
        </div>
    );
};

export default Header;
